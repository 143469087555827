
import { sendBackend } from "./useChatRoom";
const Selectconversation = (number) => {
//const [messages, setMessages] = useContext(MessagesData)

sendBackend(number)



}
export default Selectconversation;




/*import useChatRoom from "./useChatRoom";
import { DummyMessages } from "../../dummyData";
var messagesdb = DummyMessages
var senderId = ""
var isOwner=null
var incomingMessage=[]

var SelectConversation = (selectednumber) => {
    
    const [, setMessages] = useChatRoom();
    for (const index in messagesdb){ 
        if(Math.abs(messagesdb[index].phone)===selectednumber){
            for (var date in messagesdb[index].messages){
            
                        if(messagesdb[index].phone>0){
                            senderId = messagesdb[index].phone
                            isOwner=false
                        }
                        else
                        {
                            senderId = 'Agent'
                            isOwner=true
                        }
                        incomingMessage.push({'body':messagesdb[index].messages[date],'senderId':senderId,'isOwner':isOwner})
                        
                    // send the new message to the others in the same room.
                
            };
        };
        
    };

//localStorage.setItem('LSmessages', JSON.stringify(messages));
return setMessages((messages) => [...messages, incomingMessage]);
};

export default SelectConversation;*/