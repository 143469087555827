import Sidebar from "../components/sidebar/Sidebar";
import Room from "../components/room/Room";
import './home.css';
import MediaQuery from 'react-responsive'
import App from './app.js'





const Home = () => {



  return (
    <>
    <div className="login" >
      <App />
    </div>
    <MediaQuery maxWidth={1200}>
      <div className="mobileContainer" >
      <div className="mobilesidebar" >
             <Sidebar />
              </div>
      <div className="mobileroom">
             <Room />
              </div>     

            


        </div>
        </MediaQuery>
        <MediaQuery minWidth={1201}>
        <div className="homeContainer" >
            
                    <div className="homesidebar" >
                      <Sidebar />
                    </div>
        <div className="homeroom">
           <Room />
        </div>
        
       </div>
       </MediaQuery>
    </>
  );
}

export default Home