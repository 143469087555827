import {   useEffect, useRef } from "react";
import socketIOClient   from "socket.io-client";

import { useContext } from "react";
import { MessagesData , DatabaseMessages , DatabaseOrders , DatabaseNumber ,indexNumber, connectionContext , updateblockContext } from "../data/data";
import { messagesset } from "../sidebar/Sidebar";
import { sidebarselected } from "../sidebar/Sidebar";
import { handleSearch  } from "../sidebar/Sidebar";
import { updateDisplay } from "../sidebar/Sidebar";
import Cookies from 'universal-cookie';


//https://keyholesoftware.com/2021/04/01/react-with-socket-io-messaging-app/
// this is the same event name as our server. This will allow communication between the server and client possible.
export var sendTemplateMessage
export var sendMessage
export var sendBackend
export var sendPin
export var sendLogin
export var sendBlock

const cookies = new Cookies();
const NEW_MESSAGE_EVENT = "message"; 
const SOCKET_SERVER_URL = "http://chat.frontalai.com:8008"
//"http://18.141.79.84:5000";
//"http://127.0.0.1:5000";

const useChatRoom = () => {
  // eslint-disable-next-line no-unused-vars
  const [messages, setMessages] = useContext(MessagesData)
  // eslint-disable-next-line no-unused-vars
  const [Database, setDatabase] = useContext(DatabaseMessages)
  // eslint-disable-next-line no-unused-vars
  const [Orders, setOrders] = useContext(DatabaseOrders)
  // eslint-disable-next-line no-unused-vars
  const [Number, setNumber] = useContext(DatabaseNumber)
  // eslint-disable-next-line no-unused-vars
  const [selectedIndex, setSelectedIndex] = useContext(indexNumber)
  const socketRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [connection, setConnection] = useContext(connectionContext);
    // eslint-disable-next-line no-unused-vars
  const [updateblock, setupdateblock] = useContext(updateblockContext);
  useEffect(() => {
    // create a new client with our server url
    socketRef.current = socketIOClient(SOCKET_SERVER_URL);

    socketRef.current.on("connect", () => {
  //console.log('Connected');
      setConnection(1);
    });

    socketRef.current.on("disconnect", (reason) => {
      //console.log('Disconnected');
      setConnection(0);
    });



    // listen for incoming message
    socketRef.current.on(NEW_MESSAGE_EVENT, (message) => {
  
      messagesset(message)

    });


    socketRef.current.on('backend', (message) => {
      
     
     // setDatabase(JSON.parse(message).reverse())
     var msg=JSON.parse(message)
     
     setDatabase((Database)=>{
       //console.log(Database)
        try {
              var combined=[]
              for (var x = 0; x < msg.length; x++){
                //console.log(Database[x]['ID'])  
                //var d1 = new Date(Database.reverse()[0]['datetime']);
                //var d2 = new Date(msg.reverse()[x]['datetime']);
      
                if(!Database.map(a=>a.ID).includes(msg[x]['ID'])){
                  //console.log(!Database.map(a=>a.ID).includes(msg[x]['ID']))
                  combined=combined.concat(msg[x])
                  }
                //else{console.log('false')}
              } 
              combined=Database.concat(combined)
              return combined   
              
            } catch (error) {
              return msg
            }
      });
      
     /*
     for (var x = 0; x < msg.length; x++){
        setDatabase((Database)=>{
          return [Database,msg[x]]
        });
     };
     */
     updateDisplay('refresh');
     
    });
      
    socketRef.current.on('orders', (message) => {

      
      setOrders(message)
      sidebarselected(message)
      handleSearch() 

      })
      
      socketRef.current.on('block', (message) => {

        setupdateblock(message.messageBody)
  
      })



        return () => {
          socketRef.current.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    // send the messagee along with a sender id. The sender id would allow us to style the UI just like a message app like iOS.

     sendTemplateMessage = (phone,messageBody) => {
      socketRef.current.emit(NEW_MESSAGE_EVENT, {
        body: messageBody,
        senderId: -phone,
        credentials: cookies.get('username2'),
      });
}; 
    
     sendMessage = (messageBody) => {
      socketRef.current.emit(NEW_MESSAGE_EVENT, {
        body: messageBody,
        senderId: -Number,
        credentials: cookies.get('username2'),
      });
}; 
    sendBackend = (messageBody) => {
      socketRef.current.emit('backend', {
       messageBody,
      });
}; 
    sendPin = (messageBody) => {
      socketRef.current.emit('pin', {
      messageBody,
      });
}; 

    sendLogin = (messageBody) => {
      socketRef.current.emit('login', {
      messageBody,
      });
}; 

sendBlock = (messageBody) => {
  socketRef.current.emit('block', {
  messageBody,
  });
}; 



  return {messages};
    
};

export default useChatRoom;
